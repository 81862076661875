.survey_view_container {
  background-color: #d7d7d7;
  padding: 15px 27px 0 20px;
  height: 78vh;
  overflow-y: auto;
  .questions_container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    .question {
      position: relative;
      cursor: pointer;
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      width: 252px;
      height: 200px;
      flex-shrink: 0;
      padding: 16px;

      .question_text {
        max-width: 90%;
        font-size: 16px;
        color: black;
      }
      .answer_item {
        font-size: 12px;
      }
      .remove_icon {
        width: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}

.add_question_btn {
  font-size: 120px !important;
  width: 120px;
  height: auto;
  background-color: #07318f !important;
  color: #fff !important;
}

.menu_item {
  cursor: pointer;
  padding-left: 10px;
  &:hover {
    background-color: #d7d7d7;
  }
}

.option_input {
  margin-bottom: 10px;
}
