* {
  font-family: Heebo;
}

// Core

@import 'core';

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

p {
  margin-bottom: 0;
}

.danger {
  color: #f83245 !important;
}

.text-right {
  justify-content: flex-end;
}

.break-word {
  word-break: break-all;
}

.overflow-x-auto {
  overflow-x: auto;
}

.mw-100 {
  max-width: 100px;
}

.capitalize {
  text-transform: capitalize;
}
