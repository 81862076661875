.dragndrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.dropzone {
  border: 2px dashed #999; 
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 500px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-color 0.3s ease; 
  margin-bottom: 10px;
}

.dropzone:hover {
  border-color: #666;
}

.offer-content {
  padding: 32px !important;
}