.pushUpUpdates_container {
  padding: 0 16px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .save_btn {
      min-width: 200px;
      max-width: 500px;
      align-self: center;
      font-size: 24px;
    }

    .submit_btn {
      min-width: 200px;
      font-size: 16px !important;
      max-width: 300px;
      align-self: center;
      font-size: 24px;
    }
  }
}
